import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../layout/Frontend/NavbarThree";
import Footer from "../layout/Frontend/NewFooter";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ApiCalls from "../api/index";
const Constant = require("../helpers/constants");

export default function RequestDemo() {
  const [alert, setAlert] = useState({ status: "", message: "" });
  const [messageSent, setMessageSent] = useState(false);
  let appOrigin = localStorage.getItem("appOrigin");

  const validationSchema = Yup.object().shape({
    enterprise_name: Yup.string().trim().required("Name is required"),
    email: Yup.string()
      .required("Please enter valid email address")
      .matches(Constant.EMAIL_REGEX, "Please enter valid email address"),
    phone: Yup.string().trim().required("Phone is required"),
    message: Yup.string().trim().required("Message is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    document.title = `Request a Demo | ${
      appOrigin == "H"
        ? Constant.APP_NAME_DIRECT_HEALTH
        : appOrigin == "N"
        ? Constant.APP_NAME_NEURODIVERSIFY
        : Constant.APP_NAME_GENERAL
    }`;
  }, []);

  const onSubmit = async (data) => {
    ApiCalls.post(`/api/v1/request/demo`, data).then((res) => {
      if (res) {
        setMessageSent(true);
        setAlert({ status: "success", message: res.data.message });
      } else {
        setAlert({ status: "error", message: res.data.message });
      }
    });
  };

  function SubmitButton() {
    if (!isDirty || !isValid) {
      return (
        <button
          type="submit"
          disabled
          className="btn model-post-disable-btn btn-disable outline-none btn-lg-lg_8x btn-primary btn-block"
        >
          Submit
        </button>
      );
    } else {
      return (
        <button
          type="submit"
          className="btn btn-lg-lg_8x btn-primary outline-none btn-block"
        >
          Submit
        </button>
      );
    }
  }
  return (
    <>
      <Navbar />
      <div className="login_page container_bb">
        <div className="login_page_left">
          {process.env.REACT_APP_ORIGIN === "neurodiversify" ? (
            <img src="/beanbag-assets/img/neuro_login_ilustration.svg" alt="" />
          ) : (
            <img src="/beanbag-assets/img/login_ilustration.png" alt="" />
          )}
        </div>
        <div className="login_page_right">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              style={{ display: "none" }}
              className={`
                        ${
                          alert.status === "success"
                            ? "alert alert-success show"
                            : ""
                        }
                        ${
                          alert.status === "error"
                            ? "alert alert-danger show"
                            : ""
                        }`}
            >
              <span>{alert.message}</span>
            </div>
            {messageSent === false && (
              <>
                <h4>Request a Demo</h4>
                <p>Please fill all the information</p>
                <div className="form-group">
                  <label>Enterprise Name</label>
                  <input
                    type="text"
                    required=""
                    name="enterprise_name"
                    id="enterprise_name"
                    placeholder="Enter enterprise name"
                    className={`form-control ${
                      errors.enterprise_name ? "is-invalid" : ""
                    }`}
                    {...register("enterprise_name")}
                  />
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    required=""
                    name="email"
                    id="email"
                    placeholder="Enter email"
                    className={`form-control ${
                      errors.email ? "is-invalid" : ""
                    }`}
                    {...register("email")}
                  />
                  <span style={{ color: "red" }}>
                    {" "}
                    {errors.email && errors.email.message}
                  </span>
                </div>

                <div className="form-group">
                  <label>Phone Number</label>
                  <input
                    type="text"
                    required=""
                    name="phone"
                    id="phone"
                    placeholder="Enter phone number"
                    className={`form-control ${
                      errors.name ? "is-invalid" : ""
                    }`}
                    {...register("phone")}
                  />
                </div>
                <div className="form-group">
                  <label>Message</label>
                  <textarea
                    type="text"
                    required=""
                    name="message"
                    id="message"
                    rows="5"
                    cols="80"
                    placeholder="Write a message"
                    className={`form-control ${
                      errors.name ? "is-invalid" : ""
                    }`}
                    {...register("message")}
                  ></textarea>
                </div>
                <SubmitButton />
              </>
            )}
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}
