import React from 'react';
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import ApiCalls from "../api/index";
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
import c3 from "c3";
import Loader from "../Loader";

import MonsterIcon from "../assets/images/dash-monster.svg";
import IndeedIcon from "../assets/images/dash-indeed.svg";
import CareerBuilderIcon from "../assets/images/dash-cb.svg";
import LinkedInIcon from "../assets/images/dash-linkedin.svg";

import Careers4aIcon from "../assets/images/dash-careers4a.svg";
import ZipRectruiterIcon from "../assets/images/dash-zip-rectruiter.svg";
import FlexJobsIcon from "../assets/images/dash-flex-jobs.svg";
import DiceIcon from "../assets/images/dash-dice.svg";
import AccessScienceIcon from "../assets/images/dash-access-science.svg";
import EquestIcon from "../assets/images/dash-equest.svg";
import JobDivaIcon from "../assets/images/dash-job-diva.svg";
import BreezyIcon from "../assets/images/dash-breezy.svg";
import JobInventoryIcon from "../assets/images/dash-job-inventory.svg";
import PerTalentIcon from "../assets/images/dash-talent.svg";
import NeurodiversityIcon from "../assets/images/dash-diversity.svg";

import noJobsFound from "../assets/images/no-job-found.png";

import Box from "@mui/material/Box";
import { Grid, Typography, Popover } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as ArrowIcon } from "../assets/images/blue-arrow.svg";
const Constant = require("../helpers/constants");

export default function DashboardPage() {
    const [jobBoards, setJobBoards] = useState([]);
    const [overAll, setOverAll] = useState({});
    const [quickLinks, setQuickLinks] = useState({});
    const [recentJobs, setRecentJobs] = useState([]);
    const [jobPostedStats, setJobPostedStats] = useState({});
    const [jobViewsStats, setJobViewsStats] = useState({});
    const [jobAppliedStats, setJobAppliedStats] = useState({});

    const [filterData, setFilterData] = useState({});

    const [dashboardFilter, setDashboardFilter] = useState('weekly');

    let appOrigin = localStorage.getItem("appOrigin");
    const [isLoading, setIsLoading] = useState(false);

    const [jobBoard, setJobBoard] = useState([]);
    const [anchor, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const id = open ? "simple-popover" : undefined;

    let navigate = useNavigate();

    const goToJobsListing = (e) => {
        e.preventDefault();
        navigate("/admin/jobs");
    };

    const goToJobLogs = (e) => {
        e.preventDefault();
        navigate("/admin/job-logs", { state: { start_date: "", end_date: "" } });
    };

    const goToJobsBoardsAccess = (e) => {
        e.preventDefault();
        navigate("/admin/job-boards-accesses");
    };

    useEffect(() => {
        document.title = `Dashboard | ${ appOrigin == 'H' ? Constant.APP_NAME_DIRECT_HEALTH : appOrigin == 'N' ? Constant.APP_NAME_NEURODIVERSIFY : Constant.APP_NAME_GENERAL }`;
        getDashboardData();
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [dashboardFilter]);

    const ref = useRef();

    useEffect(() => {
        ref?.current?.scrollIntoView();
    }, [ref?.current]);

    const handleClick = (e, job) => {
        setJobBoard(job.job_boards);
        setAnchorEl(e.currentTarget);
        setOpen(true);
      };

      const handleClosePopover = () => {
        setOpen(false);
      };

    const getDashboardData = () => {
        setIsLoading(true);
        ApiCalls.get(`/api/v1/dashboard/data?filter=${dashboardFilter}`)
        .then((res) => {
            const { data } = res.data;
            setJobBoards(data.job_boards.slice(0, 5));
            setOverAll(data.overall);
            setQuickLinks(data.quick_links);
            setRecentJobs(data.recent_jobs.slice(0, 3));
            setJobPostedStats(data.stats.job_posted);
            setJobViewsStats(data.stats.job_views);
            setJobAppliedStats(data.stats.job_applied);

            setFilterData(data.filter);

            barDatagenerateJobPosted(data.stats.job_posted.graph);
            barDatagenerateJobViews(data.stats.job_views.graph);
            barDatagenerateJobApplied(data.stats.job_applied.graph);
            setIsLoading(false);
        }).catch(function (error) {
            setIsLoading(false);
            console.log(error);
        });
    };

    function getStatusText(status) {
        if (status === "P") {
          return "Pending";
        } else if (status === "C") {
          return "Posting Completed";
        } else if (status === "R") {
          return "Posting Rejected";
        }
    }

    function getJobPostedText() {
        if (dashboardFilter === "weekly") {
            return "This Week Jobs";
        } else if (dashboardFilter === "bi-weekly") {
          return "Last Two Weeks Jobs";
        } else if (dashboardFilter === "monthly") {
          return "This Month Jobs";
        }
    }

    function getJobViewsText() {
        if (dashboardFilter === "weekly") {
            return "This Week Views";
        } else if (dashboardFilter === "bi-weekly") {
          return "Last Two Weeks Views";
        } else if (dashboardFilter === "monthly") {
          return "This Month Views";
        }
    }

    function getJobAppliedText() {
        if (dashboardFilter === "weekly") {
            return "This Week Applications";
        } else if (dashboardFilter === "bi-weekly") {
          return "Last Two Weeks Applications";
        } else if (dashboardFilter === "monthly") {
          return "This Month Applications";
        }
    }

    function getJobPostedRatio(growth) {
        if (growth >= 0 ) {
            return <p className='card-ratio-p'>
                <ArrowIcon className='card-img green-arrow svg-color'/>
                {/* <img className='card-img green-arrow' src={ appOrigin == 'N' ? "/beanbag-assets/img/blue-arrow.svg" : "/beanbag-assets/img/green-arrow.svg" } alt="" /> */}
                &nbsp;<span className='green-arrow-ratio'>{growth}%</span></p>;
        } else {
            growth = growth * -1;
            return <p className='card-ratio-p'><img className='card-img red-arrow' src="/beanbag-assets/img/red-arrow.svg" alt="" /> &nbsp;<span className='red-arrow-ratio'>{growth}%</span></p>;
        }
    }

    function getJobViewsRatio(growth) {
        if (growth >= 0 ) {
            return <p className='card-ratio-p'>
                <ArrowIcon className='card-img green-arrow svg-color'/>
            {/* <img className='card-img green-arrow' src={ appOrigin == 'N' ? "/beanbag-assets/img/blue-arrow.svg" : "/beanbag-assets/img/green-arrow.svg" } alt="" /> */}
                &nbsp;<span className='green-arrow-ratio'>{growth}%</span></p>;
        } else {
            growth = growth * -1;
            return <p className='card-ratio-p'><img className='card-img red-arrow' src="/beanbag-assets/img/red-arrow.svg" alt="" /> &nbsp;<span className='red-arrow-ratio'>{growth}%</span></p>;
        }
    }

    function getJobAppliedRatio(growth) {
        if (growth >= 0 ) {
            return <p className='card-ratio-p'>
                <ArrowIcon className='card-img green-arrow svg-color'/>
                {/* <img className='card-img green-arrow' src={ appOrigin == 'N' ? "/beanbag-assets/img/blue-arrow.svg" : "/beanbag-assets/img/green-arrow.svg" } alt="" /> */}
                 &nbsp;<span className='green-arrow-ratio'>{growth}%</span></p>;
        } else {
            growth = growth * -1;
            return <p className='card-ratio-p'><img className='card-img red-arrow' src="/beanbag-assets/img/red-arrow.svg" alt="" /> &nbsp;<span className='red-arrow-ratio'>{growth}%</span></p>;
        }
    }

    const handleChangeFilter = (value) => {
        setDashboardFilter(value);
    };

    function getBoardImage(boardName) {
        if (boardName === 'monster') {
          return <img src={MonsterIcon} alt="" />;
        } else if (boardName === 'career-builder') {
          return <img src={CareerBuilderIcon} alt="" />;
        } else if (boardName === 'linkedin') {
            return <img src={LinkedInIcon} alt="" />;
        } else if (boardName === 'indeed') {
            return <img src={IndeedIcon} alt="" />;
        } else if (boardName === 'equest') {
            return <img src={EquestIcon} alt="" />;
        } else if (boardName === 'breezy') {
            return <img src={BreezyIcon} alt="" />;
        } else if (boardName === 'jobdiva') {
            return <img src={JobDivaIcon} alt="" />;
        } else if (boardName === 'zip-recruiter') {
            return <img src={ZipRectruiterIcon} alt="" />;
        } else if (boardName === 'flex-jobs') {
            return <img src={FlexJobsIcon} alt="" />;
        } else if (boardName === 'careers4a') {
            return <img src={Careers4aIcon} alt="" />;
        } else if (boardName === 'job-inventory') {
            return <img src={JobInventoryIcon} alt="" />;
        } else if (boardName === 'access-science') {
            return <img src={AccessScienceIcon} alt="" />;
        } else if (boardName === 'dice') {
            return <img src={DiceIcon} alt="" />;
        } else if (boardName === 'talent') {
            return <img src={PerTalentIcon} alt="" />;
        } else if (boardName === 'diversity') {
            return <img src={NeurodiversityIcon} alt="" />;
          }
    }

    function timeGreetings() {
        var day = new Date();
        var hr = day.getHours();
        if (hr >= 0 && hr < 12) {
            return "Good Morning!";
        } else if (hr == 12) {
            return "Good Afternoon!";
        } else if (hr >= 12 && hr <= 17) {
            return "Good Afternoon!";
        } else {
            return "Good Evening!";
        }
    }

    const chartRenderJobPosted = (colors_arrays, value_arrays) => {
        var chart = c3.generate({
            bindto: '#chartOne',
            data: {
                columns: value_arrays,
                type: 'bar',
                colors: colors_arrays
            },
            bar: {
                width: 34
            },
            axis: {
                x: {
                    // type: 'category',
                    // categories: name_arrays
                    show: false
                },
                y: {
                    show: false,
                    min: 0,
                    padding: { bottom: 0 }
                }
            },
            legend: {
                show: false
            },
            size: {
                width: 200,
                height: 100
            },
            padding: {
                // bottom: -100,
                left: 110
            },
            bar: {
                space: 0.100,
                width: 40,
            }
        });
    };

    const chartRenderJobViews = (colors_arrays, value_arrays) => {
        var chart = c3.generate({
            bindto: '#chartTwo',
            data: {
                columns: value_arrays,
                type: 'bar',
                colors: colors_arrays
            },
            bar: {
                width: 34
            },
            axis: {
                x: {
                    // type: 'category',
                    // categories: name_arrays
                    show: false
                },
                y: {
                    show: false,
                    min: 0,
                    padding: { bottom: 0 }
                }
            },
            legend: {
                show: false
            },
            size: {
                width: 200,
                height: 100
            },
            padding: {
                // bottom: -100,
                left: 110
            },
            bar: {
                space: 0.100,
                width: 40,
            }
        });
    };

    const chartRenderJobApplied = (colors_arrays, value_arrays) => {
        var chart = c3.generate({
            bindto: '#chartThree',
            data: {
                columns: value_arrays,
                type: 'bar',
                colors: colors_arrays
            },
            bar: {
                width: 34
            },
            axis: {
                x: {
                    // type: 'category',
                    // categories: name_arrays
                    show: false
                },
                y: {
                    show: false,
                    min: 0,
                    padding: { bottom: 0 }
                }
            },
            legend: {
                show: false
            },
            size: {
                width: 200,
                height: 100
            },
            padding: {
                // bottom: -100,
                left: 110
            },
            bar: {
                space: 0.100,
                width: 40,
            }
        });
    };

    const barDatagenerateJobPosted = (data) => {
        let colors_arrays = [];
        let value_arrays = [];
        for (let i = 0; i < data.length; i++) {
            let color = '';
            if (i === 0) {
                color = 'Red';
            } else {
                color = 'LightGreen';
            }
            let key = data[i].name;
            let nameColorsArray = {
                [key]: color
            };
            colors_arrays.push(nameColorsArray);
        }

        for (let i = 0; i < data.length; i++) {
            let nameAndValueArray = [];
            nameAndValueArray.push(data[i].name);
            nameAndValueArray.push(data[i].value);
            value_arrays.push(nameAndValueArray);
        }
        chartRenderJobPosted(colors_arrays, value_arrays);
    };

    const barDatagenerateJobViews = (data) => {
        let colors_arrays = [];
        let value_arrays = [];
        for (let i = 0; i < data.length; i++) {
            let color = '';
            if (i === 0) {
                color = 'Red';
            } else {
                color = 'LightGreen';
            }
            let key = data[i].name;
            let nameColorsArray = {
                [key]: color
            };
            colors_arrays.push(nameColorsArray);
        };

        for (let i = 0; i < data.length; i++) {
            let nameAndValueArray = [];
            nameAndValueArray.push(data[i].name);
            nameAndValueArray.push(data[i].value);
            value_arrays.push(nameAndValueArray);
        }
        chartRenderJobViews(colors_arrays, value_arrays);
    };

    const barDatagenerateJobApplied = (data) => {
        let colors_arrays = [];
        let value_arrays = [];
        for (let i = 0; i < data.length; i++) {
            let color= '';
            if (i === 0) {
                color = 'Red';
            } else {
                color = 'LightGreen';
            }
            let key = data[i].name;
            let nameColorsArray = {
                [key]: color
            };
            colors_arrays.push(nameColorsArray);
        }

        for (let i = 0; i < data.length; i++) {
            let nameAndValueArray = [];
            nameAndValueArray.push(data[i].name);
            nameAndValueArray.push(data[i].value);
            value_arrays.push(nameAndValueArray);
        }
        chartRenderJobApplied(colors_arrays, value_arrays);
    };



    return (
    <>
    <Grid item lg={12} md={12} xs={12}>
    {isLoading && <Loader />}
    <Grid item lg={12} md={12} xs={12} className="dash_content" ref={ref} id="dashboard">

          <div className="row p-l-5">
              <h4>
                  <span className='heading-text-color'>Dashboard</span>
              </h4>
              </div>
              <div className="row sub-sec-header">
                  <div className="d-flex">
                  <div className="building building_2">
                      <img src="/beanbag-assets/img/client-logo2.svg" className="p-3" alt='' />
                      <img src="/beanbag-assets/img/client-logo1.svg" alt='' />
                  </div>
                  <div>
                      <p className="hello text-black">Hello, {timeGreetings()}</p>
                      <p className="hello_2 text-black">
                          {localStorage.getItem('enterprise_name')}
                      </p>
                  </div>
                  </div>
                  <div className="sun"></div>
                  <div className="right-dash-con-head">
                      <div className="sub-dash-con-head">
                          <p className="total text-black">Total Jobs</p>
                          <p className="total_2 text-black">{overAll.total_jobs}</p>
                      </div>
                      <div className="sub-dash-con-head">
                          <p className="total text-black">Closed Jobs</p>
                          <p className="total_2 text-black">{overAll.closed_jobs}</p>
                      </div>
                      <div className="sub-dash-con-head">
                          <p className="total text-black">Success Ratio</p>
                          <p className="total_2 text-black">{overAll.success_ratio}</p>
                      </div>
                  </div>
              </div>
              <div className="row status_col">
                  <div className="col-md-10 status_col_9 col-sm-12 col-xs-12">
                      <div className="status">
                          <h4>Statistics</h4>
                          <div className="choose_month">
                              <div className="weekly">
                                  <button style={{ width: "134px", color: dashboardFilter === "weekly" ? "#fff" : "var(--primary-color)",
                                  background: dashboardFilter === "weekly" ? "var(--primary-color)" : "var(--secondary-color)",
                                  outline: "none" }}
                                  onClick={ (e) =>  handleChangeFilter('weekly') }
                                  className="btn btn-weekly">Weekly</button>
                              </div>
                              <div className="weekly_main" style={{ zIndex: "99999" }}>
                                  <button style={{ width: "134px", color: dashboardFilter === "bi-weekly" ? "#fff" : "var(--primary-color)",
                                  background: dashboardFilter === "bi-weekly" ? "var(--primary-color)" : "var(--secondary-color)",
                                  outline: "none" }} onClick={ (e) =>  handleChangeFilter('bi-weekly') } className="btn btn-weekly_main">Biweekly</button>
                              </div>
                              <div className="weekly_main" style={{ zIndex: "99999" }}>
                                  <button style={{ width: "134px", color: dashboardFilter === "monthly" ? "#fff" : "var(--primary-color)",
                                  background: dashboardFilter === "monthly" ? "var(--primary-color)" : "var(--secondary-color)",
                                  outline: "none" }} onClick={ (e) =>  handleChangeFilter('monthly') } className="btn btn-weekly_main">Monthly</button>
                              </div>
                          </div>
                      </div>
                      <div className="plugin">
                          <div className="row statistics chart-tooltip">
                              <div className="col-md-4 sub_plugin p-inherit">
                                  <p className='card-heading'>Job(s) Posted</p>
                                  <p className='card-count'>{jobPostedStats.total}</p>
                                  {getJobPostedRatio(jobPostedStats.growth)}
                                  <p className='card-footer-text'>{ getJobPostedText()}</p>
                                  <div id="chartOne" className="bar-chart dashboard_bar-chart statistics_bar_chart"></div>
                              </div>
                              <div className="col-md-4 sub_plugin sub_plugin_sec">
                                  <p className='card-heading'>Job Views</p>
                                  <p className='card-count'>{jobViewsStats.total}</p>
                                  {getJobViewsRatio(jobViewsStats.growth)}
                                  <p className='card-footer-text'>{ getJobViewsText()}</p>
                                  <div id="chartTwo" className="bar-chart dashboard_bar-chart statistics_bar_chart"></div>
                              </div>
                              <div className="col-md-4 sub_plugin sub_plugin_sec">
                                  <p className='card-heading'>No. of Applicants</p>
                                  <p className='card-count'>{jobAppliedStats.total}</p>
                                  {getJobAppliedRatio(jobAppliedStats.growth)}
                                  <p className='card-footer-text'>{ getJobAppliedText()}</p>
                                  <div id="chartThree" className="bar-chart dashboard_bar-chart statistics_bar_chart"></div>
                              </div>
                          </div>
                          <h4>
                              Recently Added Jobs

                          </h4>
                      </div>
                      <div className="table_inner table_pad table-responsive">
                          <table className="table dashboard-job-listing-table width-100">
                              <thead>
                                  {recentJobs && recentJobs.length === 0
                                  ?
                                      ''
                                  :
                                  <tr className="tr_target tr_target_main">
                                      <th width="2%"></th>
                                      <th width="30%">Job Title</th>
                                      <th width="20%">Job Reference #</th>
                                      <th width="30%" className="dashboard-job-boards">Job Board</th>
                                      <th width="18%">Posted Date</th>
                                      {/* <th className="center_heading">Status</th> */}
                                  </tr>
                                  }
                              </thead>
                              {recentJobs && recentJobs.length === 0
                              ?
                              <tbody>
                                  <tr className="tr_target">
                                      <td colSpan={6} className="th_target center_heading">
                                          <img src={noJobsFound} alt="img" />
                                          <p className='no-data-found'>No jobs found</p>
                                      </td>
                                  </tr>
                              </tbody>
                              :
                              <tbody>
                                  {recentJobs && recentJobs?.map( (job, index) => {
                                      return (
                                          <>
                                              <tr className="tr_target list-hover-color" key={index}>
                                                  <td className="td_target">{index+1}.</td>
                                                  <td  className="td_target bold_heading">
                                                  <span className="target_span"></span> {job.job_title}</td>
                                                  <td className="td_target">{job.job_reference_number ? job.job_reference_number : "-"}</td>
                                                  <td className="td_target">
                                                      {job.job_boards.length <= 0 ? <Box>-</Box> :
                                                          <Box>
                                                              {job.job_boards.map((boards, index) => {
                                                              return index < 3 ?
                                                                  (
                                                                      <span key={index} className="boards text-initial">
                                                                          {boards}{index === job.job_boards.length - 1 ? " " : ", "}
                                                                      </span>
                                                                  ) : null;
                                                              })}
                                                              {
                                                              job.job_boards.length > 3 && (
                                                                  <Box style={{ display: 'inline-flex' }}>
                                                                          <a
                                                                          className="primary-color cursor-pointer"
                                                                          onClick={(e)=>handleClick(e, job)}
                                                                          >{`+${job.job_boards.length - 3} more`}</a>
                                                                  </Box>
                                                              )
                                                              }
                                                          </Box>
                                                      }
                                                  </td>
                                                  <td className="td_target dashboard-job-posted">{moment(job.request_timestamp).format("MMM DD, YYYY HH:mm")}</td>
                                                  {/* <td className="td_target center_heading">
                                                      <div className="form-group">
                                                      <label style={{background: '#5bc0de'}} className={`label
                                                          ${job.status === 'C'?'label-info':job.status === 'R'?'label-info':'label-info'}`}>{ getStatusText(job.status)}
                                                      </label>
                                                      </div>
                                                  </td> */}
                                              </tr>
                                          </>
                                      );
                                  })}
                              </tbody>
                              }
                          </table>
                      </div>
                      {quickLinks.job_listing_count > 3
                          ?
                              <div className="view_button">
                                  <a className='primary-color cursor-pointer' onClick={goToJobsListing}>View More</a>
                              </div>
                          :
                              ''
                      }
                  </div>
                  <div className="col-md-2 dash_col_3">
                      <ul className="list-unstyled ul_first_view">
                          <li className="li_1" style={{ display: 'flex', marginTop: 0, marginBottom: "-12px" }}><p>Quick Links</p></li>
                              {quickLinks.job_failed_count > 0
                                  ?
                                      <li className="li_second quick-links" style={{ marginBottom: "-12px" }}>
                                          <div className="job_listing">
                                            <div className="popup_span">
                                                <div className="sub-p-s"></div>
                                            </div>
                                            <a onClick={goToJobLogs} className='cursor-pointer'>
                                                Job Logs
                                            </a>
                                            <div className="badge badge-per">
                                                <span>{quickLinks.job_failed_count}</span>
                                            </div>
                                          </div>
                                          <a onClick={goToJobLogs} className="anchor_u cursor-pointer dsaas-color primary-color" style={{ float: "left" }}>View Logs
                                              {/* <i><img src="/beanbag-assets/img/Arrow 3.svg" alt="" /></i>  */}
                                          </a>
                                          <hr className="hor_f" />
                                      </li>
                                  :
                                      ''
                              }
                          <li className="li_second quick-links" style={{ marginBottom: "-12px" }}>
                              <div className="job_listing">
                                <div className="popup_span">
                                    <div className="sub-p-s"></div>
                                </div>
                                <a onClick={goToJobsListing} className='cursor-pointer'>
                                    Job Listing
                                </a>
                                <div className="badge badge-per">
                                    <span>{quickLinks.job_listing_count}</span>
                                </div>
                              </div>
                              <a onClick={goToJobsListing} className="anchor_u cursor-pointer " style={{ float: "left", color: "var(--primary-color)" }}>View Jobs
                                  {/* <i><img src="/beanbag-assets/img/Arrow 3.svg" alt="" /></i>  */}
                              </a>
                              <hr className="hor_f" />
                          </li>
                          <li className="li_second quick-links mb-30">
                              <div className="job_listing">
                                <div className="popup_span">
                                    <div className="sub-p-s"></div>
                                </div>
                                <a onClick={goToJobsBoardsAccess} className='cursor-pointer'>
                                    Job Boards
                                </a>
                                <div className="badge badge-per">
                                    <span>{quickLinks.job_board_count}</span>
                                </div>
                              </div>
                              <a onClick={goToJobsBoardsAccess} className="anchor_u cursor-pointer" style={{ float: "left", color: "var(--primary-color)" }}>View Boards
                                  {/* <i><img src="/beanbag-assets/img/Arrow 3.svg" alt="" /></i>  */}
                              </a>
                          </li>
                      </ul>
                  </div>
                  {quickLinks.job_board_count > 0
                  ?
                      <div className="col-md-2 job_boards_block db_job_job_boards">
                          <ul className="list-unstyled ul_second_view">
                              <li className="li_1">
                                  <h5>Job Boards</h5>
                                  <a onClick={goToJobsBoardsAccess} className='cursor-pointer primary-color'>
                                      <span>See All
                                          {/* <i><img src="/beanbag-assets/img/Arrow 3.svg" alt="" /></i> */}
                                      </span>
                                  </a>
                              </li>
                              {jobBoards.map( (board, index) => {
                                  return (
                                      <>
                                          <li key={index}>
                                              <div className="job_listing">
                                              <span className="order_span">{index+1}.</span>
                                              <div>
                                                  {getBoardImage(board.job_board_id)}
                                              </div>
                                              <div className="listing_text">
                                                  <a className='main-text-color'>{board.job_board_name}</a>
                                              </div>
                                              </div>
                                              <p style={{ display: 'none' }} className="listing_para">Added on 23 Jan, 2022</p>
                                              {index < 4 ?
                                                  <hr className="hor_f" />
                                                  :
                                                  ''
                                              }
                                          </li>
                                      </>
                                  );
                              })}
                          </ul>
                      </div>
                  :
                      <div className="col-md-2 empty_boards_block">
                          <ul className="list-unstyled ul_second_view ul_third_view">
                              <li className="li_1">
                                  <h5>Job Boards</h5>
                              </li>
                              <img src="/beanbag-assets/img/undraw_job_hunt_re_q203 1.png" alt='' className="sec_gen_img" />
                              <p>No job boards added
                              at the moment</p>
                              <button onClick={goToJobsBoardsAccess} className="btn btn-add" style={{ backgroundColor: "var(--primary-color)", borderColor: "var(--primary-color)" }}>Add Job Board</button>
                          </ul>
                      </div>
                  }
                  {/* <ToastContainer/> */}
              </div>
          </Grid>
    </Grid>
    <Popover
        id={id}
        open={open}
        anchorEl={anchor}
        onClose={handleClosePopover}
        anchorOrigin={{
            vertical: "-500",
            horizontal: "200",
            }}
            anchorPosition={{
            left: 500,
            }}
            transformOrigin={{
            vertical: "300",
            horizontal: "200",
            }}
        >
            <Grid item sx={{ p: 1 }}>
               <Box className="align-between">
                  <Grid item xs={12}>
                     <Typography
                        id=""
                        className="d-flex align-between p-10"
                     >
                        <Typography variant="h5" className="f-16 font-weight-600">
                           Job Board
                        </Typography>
                        <Typography
                           align="right"
                           className="cursor-hover gray7 icon-button"
                        >
                           <CloseIcon
                           onClick={handleClosePopover}
                           onKeyPress={handleClosePopover}
                           aria-label="Close icon"
                           />
                        </Typography>
                     </Typography>
                  </Grid>
               </Box>
               <Grid
               className="p-10"
               style={{ maxHeight: "250px", overflow: "auto", maxWidth: "300px" }}
               >
                  <Box>
                     {jobBoard.map((boards, index) => {
                        return (
                           <span key={index} className="job_boards">
                           {boards}
                           </span>
                        );
                     })}
                  </Box>
               </Grid>
            </Grid>
    </Popover>
    </>
    );
}
