import $ from "jquery";

import ZipRectruiterIcon from "../assets/images/per-ziprecruiter.svg";
import CareerBuuilderIcon from "../assets/images/per-cb.svg";
import MonsterIcon from "../assets/images/per-monster.svg";
import IndeedIcon from "../assets/images/per-indeed.svg";
import LinkedinIcon from "../assets/images/per-linkedin.svg";
import DiceIcon from "../assets/images/dice.svg";
import BreezyIcon from "../assets/images/breezy.svg";
import EquestIcon from "../assets/images/equest.svg";
import Careers4aIcon from "../assets/images/careers4a.svg";
import FlexJobsIcon from "../assets/images/flexjobs.svg";
import JobDivaIcon from "../assets/images/jobdiva.svg";
import JobInventoryIcon from "../assets/images/per-jobinventory.png";
import PerTalentIcon from "../assets/images/per-talent.svg";
import AccessScienceIcon from "../assets/images/per-access-science.svg";
import NeurodiversityIcon from "../assets/images/per-diversity.svg";
import KeycloakService from "../keycloak";

export const formatDate = (date) => {
  var d;
  if (typeof date === "string") {
    d = new Date(date);
  } else {
    d = date;
  }

  if (d instanceof Date) {
    var month = "" + (d.getMonth() + 1);
    var day = "" + d.getDate();
    var year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  } else {
    return null;
  }
};

export const closeModelWindow = () => {
  $(".modal").attr("aria-hidden", "true");
  $(".modal").css("display", "none");
  $(".modal-backdrop").remove();
  $("body").removeClass("modal-open");
  $("body").addClass("modal-close");
};

export const showModelWindow = () => {
  $(".model")
    .show()
    .on("shown", function () {
      $(".model").modal("show");
    });
};

export const openSideMenu = () => {
  $(".dropdown").mouseover(function () {
    $(".dropdown-menu").fadeIn();
  });
};

export const closeSideMenu = () => {
  $(".dropdown").mouseleave(function () {
    $(".dropdown-menu").fadeOut();
  });
};

export const getBoardImage = (boardName) => {
  if (boardName === "monster") {
    return <img src={MonsterIcon} alt="" />;
  } else if (boardName === "career-builder") {
    return <img src={CareerBuuilderIcon} alt="" />;
  } else if (boardName === "linkedin") {
    return <img src={LinkedinIcon} alt="" />;
  } else if (boardName === "indeed") {
    return <img src={IndeedIcon} alt="" />;
  } else if (boardName === "zip-recruiter") {
    return <img src={ZipRectruiterIcon} alt="" />;
  } else if (boardName === "equest") {
    return <img src={EquestIcon} alt="" />;
  } else if (boardName === "breezy") {
    return <img src={BreezyIcon} alt="" />;
  } else if (boardName === "jobdiva") {
    return <img src={JobDivaIcon} alt="" />;
  } else if (boardName === "flex-jobs") {
    return <img src={FlexJobsIcon} alt="" />;
  } else if (boardName === "talent") {
    return <img src={PerTalentIcon} alt="" />;
  } else if (boardName === "job-inventory") {
    return <img src={JobInventoryIcon} alt="" />;
  } else if (boardName === "dice") {
    return <img src={DiceIcon} alt="" />;
  } else if (boardName === "access-science") {
    return <img src={AccessScienceIcon} alt="" />;
  } else if (boardName === "careers4a") {
    return <img src={Careers4aIcon} alt="" />;
  } else if (boardName === "diversity") {
    return <img src={NeurodiversityIcon} alt="" />;
  }
  // else if (boardName === 'wwr') {
  //        return <img src={IndeedIcon} alt="" />;
  // }
};

export const handleSpaces = (event) => {
  var e = window.event || event;

  var key = e.keyCode;

  //space pressed

  if (key === 32) {
    //space

    if (event.target.value < 1) event.preventDefault();
  }
};

export const setPlatformTheme = (theme_obj) => {
  let appOrigin = localStorage.getItem("appOrigin");
  let primaryColor = (appOrigin === "N" ?  "#055094" : appOrigin === "H" ? "#45AC8B" : "#150349");
  let secondaryColor = (appOrigin === "N" ? "#EAF4FF" : appOrigin === "H" ? "#F0FFFA" : "#EEEDFE");
  let hoverButtonColor = (appOrigin === "N" ?  "#055094" : appOrigin === "H" ? "#45AC8B" : "#FF9F7C");
  let hoverButtonTextColor = "#FFFFFF";
  let headingTextColor = "#101828";
  let mainTextColor = (appOrigin === "N" ?  "#055094" : appOrigin === "H" ? "#45AC8B" : "#150349");
  let listHoverColor = "#F9FAFB";
  try {
    let themes = theme_obj ? JSON.parse(theme_obj) : [];
    let selectedTheme = themes?.filter((theme)=>(theme.isSelected));
    if (selectedTheme.length) {
      selectedTheme = selectedTheme[0];
      primaryColor = "#" + selectedTheme.primaryColor;
      secondaryColor = "#" + selectedTheme.secondaryColor;
      hoverButtonColor = "#" + selectedTheme.hoverButtonColor;
      hoverButtonTextColor = "#" + selectedTheme.hoverButtonTextColor;
      headingTextColor = "#" + selectedTheme.headingTextColor;
      mainTextColor = "#" + selectedTheme.mainTextColor;
      listHoverColor = "#" + selectedTheme.listHoverColor;
    }
  } catch (err) {}
  localStorage.setItem("primaryColor", primaryColor);
  localStorage.setItem("secondaryColor", secondaryColor);
  localStorage.setItem("hoverButtonColor", hoverButtonColor);
  localStorage.setItem("hoverButtonTextColor", hoverButtonTextColor);
  document.documentElement.style.setProperty(
    "--primary-color",
    primaryColor,
    "important"
  );
  document.documentElement.style.setProperty(
    "--secondary-color",
    secondaryColor,
    "important"
  );
  document.documentElement.style.setProperty(
    "--hover-button-color",
    hoverButtonColor,
    "important"
  );
  document.documentElement.style.setProperty(
    "--hover-button-text-color",
    hoverButtonTextColor,
    "important"
  );
  document.documentElement.style.setProperty(
    "--heading-text-color",
    headingTextColor,
    "important"
  );
  document.documentElement.style.setProperty(
    "--main-text-color",
    mainTextColor,
    "important"
  );
  document.documentElement.style.setProperty(
    "--list-hover-color",
    listHoverColor,
    "important"
  );
};

export const logout = async () => {
  try {
    if (KeycloakService.getToken()) {
      localStorage.clear();
      await KeycloakService.doLogout({ redirectUri: `${window.location.origin}/` });
    }
    localStorage.clear();
  } catch {
    return;
  }
};

export const getUserType = (userType) => {
  switch (userType) {
    case "Z":
      return "Super Administrator";
    case "A":
      return "Administrator";
    case "S":
      return "Associate";
    case "R":
      return "Recruiter";
    case "H":
      return "HR Manager";
    case "T":
      return "Talent Acquisition Manager";
    case "E":
      return "Executive Leader";
    default:
      return "-";
  }
};
