import React from 'react';
import ApiCalls from "../api/index";
import { useState, useEffect, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../Loader";
import { Box, Button, Modal, Typography } from '@mui/material';
const Constant = require("../helpers/constants");

export default function AccountSettingPage() {
    const [apiAccessKey, setApiAccessKey] = useState('');
    const [testMode, setTestMode] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isActiveJobListing, setIsActiveJobListing] = useState(false);
    const [openActiveJobListingModal, setOpenActiveJobListingModal] = useState(false);
    let appOrigin = localStorage.getItem("appOrigin");

    useEffect(() => {
        document.title = `Account Settings | ${ appOrigin == 'H' ? Constant.APP_NAME_DIRECT_HEALTH : appOrigin == 'N' ? Constant.APP_NAME_NEURODIVERSIFY : Constant.APP_NAME_GENERAL }`;
        getSettingData();
        getBoardsActiveJobListing();
        window.scrollTo({ top: 0 });
    }, []);

    const ref = useRef();

    useEffect(() => {
        ref?.current?.scrollIntoView();
    }, [ref?.current]);

    const getSettingData = () => {
        setIsLoading(true);
        ApiCalls.get(`/api/v1/cosumer/account-setting`).then((res) => {
            const { data } = res.data;
            setApiAccessKey(data.api_access_key);
            setTestMode(data.mode);
            localStorage.setItem('api_access_key', data.api_access_key);
            localStorage.setItem('api_access_key', data.mode);
            setIsLoading(false);
        }).catch(function (error) {
            setIsLoading(false);
            console.log(error);
        });
    };

    const getBoardsActiveJobListing = () => {
        setIsLoading(true);
        ApiCalls.get(`/api/v1/dashboard/data`).then((res) => {
            const { data } = res.data;
            setIsActiveJobListing(data?.job_boards?.filter((job_board)=>(job_board.job_count > 0))?.length > 0);
            setIsLoading(false);
        }).catch(function (error) {
            setIsLoading(false);
        });
    };

    const handleChangeTestLiveMode = (mode) => {
        var formData = new FormData();
        formData.append("mode", mode);
        ApiCalls.post(`/api/v1/cosumers/update/mode`, formData )
        .then((res) => {
            setTestMode(res.data.data.mode);
            localStorage.setItem('mode', res.data.data.mode);
            if (mode == 'L') {
                toast.success("Live mode enabled successfully.");
            } else {
                toast.success('Test mode enabled successfully.');
            }
        }).catch(function (error) {
            console.log(error);
            // toast.success(error.data.message)
        });
    };

    const handleChangeApiAccessKey = () => {
        ApiCalls.post(`/api/v1/cosumers/generate/key` )
        .then((res) => {
            setApiAccessKey(res.data.data.api_access_key);
            localStorage.setItem('api_access_key', res.data.data.api_access_key);
            toast.success(res.data.message);
        }).catch(function (error) {
            console.log(error);
            // toast.success(error.data.message)
        });
    };

    const handleChange = (e) => {
        let isChecked = e.target.checked;
        if (isChecked) {
            handleChangeTestLiveMode('L');
        } else if (isActiveJobListing) {
            setOpenActiveJobListingModal(true);
        } else {
            handleChangeTestLiveMode('T');
        }
    };

    const getApiDocument = () => {
        window.open("https://apidoc.simplifyhire.com/dsaasaiapireference.html#tag/BeanBag-Integration");
    };

    return (
        <>
            <div className="dash_content dash_content_two" ref={ ref } style={{ height: '100vh' }} id="account-setting">
                {isLoading && <Loader />}
                <div className="access_main">
                    <div className="row">
                        <div className="col-md-4 access_key_col_1 p-inherit">
                            <h3><span className='heading-text-color'>BeanBag Access Key</span></h3>
                            <p className="pb-20">
                                You can see and generate new access
                                key for your account
                            </p>
                            {/* <a onClick={ () => getApiDocument() } style={{cursor: 'pointer'}}>API Document</a> */}
                        </div>
                        <div className="col-md-8 access_key_col_2">
                            <div className="row mode">
                                <p className='font-weight-500 gray9'>Change Mode</p>
                                <div className="row live_mode">
                                    <p style={{ color: testMode === "T" ? "var(--primary-color)" : "#1F1F1F", paddingTop: "1px" }}>Test Mode</p>
                                    <div className="test_mode">
                                        <label className="switch mode-switch">
                                            <input
                                                type="checkbox"
                                                onChange={handleChange}
                                                checked={testMode === 'L' ? true : false}
                                            />
                                            <span className="slider"></span>
                                        </label>
                                    </div>
                                    <p className="test_p" style={{ color: testMode === "L" ? "var(--primary-color)" : "#1F1F1F" }}>Live Mode</p>
                                </div>
                            </div>
                            <div className="row access_key_col_3 table-responsive">
                                <table className="table dash_table">
                                    <thead className="access_table" style={{ borderBottom: 'none' }}>
                                        <tr className="tr_target_main">
                                            <th scope="col" className="first-heading">Access Key</th>
                                            {/* <th scope="col" className="sec-heading">Created at</th> */}
                                            <th scope="col" className="third-heading text-center">Expiry</th>
                                            <th scope="col" className="forth-heading text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="td_1">{ apiAccessKey }</td>
                                            {/* <td className="td_2">July 13, 2022 11:58 PM</td> */}
                                            <td className="td_3 text-center api-key-expiry">Never</td>
                                            <td className="td_4 text-center">
                                                <button onClick={() => handleChangeApiAccessKey()} className="btn btn_g_key gen-key-align generate-key">Generate New Key</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <ToastContainer/> */}
            <Modal
                open={openActiveJobListingModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    className="inactive-modal inputcontrasts outline-none"
                >
                <Typography variant="h5" className="font-weight-500 f-20  gray9">
                    {`Existing job listings will not be removed from the respective job boards. Jobs can be removed using the "Remove from Job Board" option in the Job Listing.`}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 3 }}>
                    <Button
                        type="button"
                        onClick={() => {
                            setOpenActiveJobListingModal(false);
                            handleChangeTestLiveMode('T');
                        }}
                        className="btn primary-button w-40 mt-20"
                        variant="contained"
                        disableRipple="true"
                    >
                        OK
                    </Button>
                </Typography>
                </Box>
            </Modal>
        </>
    );
}
