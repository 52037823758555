import React from "react";
import Navbar from "../layout/Frontend/Navbar";
import Footer from "../layout/Frontend/NewFooter";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import ApiCalls from "../api/index";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Axios from "axios";
import AccessService from "../Services/Access.service";
import Loader from "../Loader";
import KeycloakService from "../keycloak";
import { setPlatformTheme } from "../helpers/helper";
const Constant = require("../helpers/constants");

export default function SSOLogin() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [testMode, setTestMode] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  let appOrigin = localStorage.getItem("appOrigin");

  let navigate = useNavigate();

  let baseURL = process.env.REACT_APP_BASE_URL;

  const getDetails = async () => {
    let payloads = {};
    let token = localStorage.getItem("token");
    let accessToken = localStorage.getItem("accessToken");
    payloads.idToken = "Bearer " + token;
    payloads.accessToken = accessToken;
    if (localStorage.getItem("enterpriseId")) {
      payloads.enterpriseId = localStorage.getItem("enterpriseId");
    }
    if (localStorage.getItem("appOrigin") == "N") {
      document.documentElement.style.setProperty(
        "--primary-color",
        "#055094",
        "important"
      );
      document.documentElement.style.setProperty(
        "--secondary-color",
        "#EAF4FF",
        "important"
      );
      document.documentElement.style.setProperty(
        "--font-family",
        "Lato",
        "important"
      );
      document.documentElement.style.setProperty(
        "--font-size-10",
        "12px",
        "important"
      );
      document.documentElement.style.setProperty(
        "--font-size-12",
        "14px",
        "important"
      );
      document.documentElement.style.setProperty(
        "--font-size-14",
        "16px",
        "important"
      );
      document.documentElement.style.setProperty(
        "--font-size-16",
        "18px",
        "important"
      );
      document.documentElement.style.setProperty(
        "--font-size-18",
        "20px",
        "important"
      );
      document.documentElement.style.setProperty(
        "--font-size-20",
        "24px",
        "important"
      );
      document.documentElement.style.setProperty(
        "--font-size-24",
        "26px",
        "important"
      );
      document.documentElement.style.setProperty(
        "--font-size-28",
        "30px",
        "important"
      );
      document.documentElement.style.setProperty(
        "--font-size-36",
        "38px",
        "important"
      );
      document.documentElement.style.setProperty(
        "--gray6",
        "#344054",
        "important"
      );
      document.documentElement.style.setProperty(
        "--gray7",
        "#344054",
        "important"
      );
      document.documentElement.style.setProperty(
        "--gray8",
        "#1D2939",
        "important"
      );
      document.documentElement.style.setProperty(
        "--gray9",
        "#1D2939",
        "important"
      );
      document.documentElement.style.setProperty(
        "--gray10",
        "#1D2939",
        "important"
      );
      document.documentElement.style.setProperty(
        "--gray11",
        "#1D2939",
        "important"
      );
    }
    await AccessService.getEnterpriseData(payloads).then((res) => {
      let enterpriseUser = res.data.responsePayload[0];
      setPlatformTheme(enterpriseUser.theme);
      localStorage.setItem("enterprise_name", enterpriseUser.establishmentName);
      const firstName = enterpriseUser.firstName ?? '';
      const lastName = enterpriseUser.lastName ?? '';
      localStorage.setItem("user_name", `${firstName} ${lastName}`);
      localStorage.setItem(
        "profile_image",
        enterpriseUser.logoFileSignedUrl
          ? enterpriseUser.logoFileSignedUrl
          : "https://daas-prog-test.s3.amazonaws.com/default-logo.png"
      );
      localStorage.setItem("email", enterpriseUser.emailAddress);
      localStorage.setItem("userType", enterpriseUser.userType);
      localStorage.setItem("profileTitle", enterpriseUser.profileTitle);
      if (enterpriseUser.userType === "T" || enterpriseUser.userType === "R" || enterpriseUser.userType === "A" || enterpriseUser.userType === "Z") {
        toast.success('Logged in successfully.');
        localStorage.setItem("is_admin", true);
        navigate("/admin/dashboard");
      } else {
        localStorage.setItem("is_admin", false);
        navigate("/");
      }
    });
  };
  useEffect(() => {
    const asyncOps = async () => {
      try {
        await authorizeSSO();
      } catch (err) {
        console.error('error while sso login backend request ->', err);
      }
    };

    if (KeycloakService.isLoggedIn()) {
      asyncOps();
    } else {
      setIsLoading(false);
      navigate("/");
    }
  }, []);

  const authorizeSSO = () => new Promise((resolve, reject) => {
    document.documentElement.style.setProperty(
        "--loader-bg",
        "#d6d7d9",
        "important"
    );
    document.title = `SSO Login | ${
        appOrigin == "H"
            ? Constant.APP_NAME_DIRECT_HEALTH
            : appOrigin == "N"
                ? Constant.APP_NAME_NEURODIVERSIFY
                : Constant.APP_NAME_GENERAL
    }`;
    let payload = {
      token: localStorage.getItem("token"),
      accessToken: localStorage.getItem("accessToken")
    };
    if (params.get("enterpriseId") || localStorage.getItem("enterpriseId")) {
      payload["enterpriseId"] = params.get("enterpriseId") ? params.get("enterpriseId") : localStorage.getItem("enterpriseId");
    }
    Axios.post(baseURL + `/api/v1/auth/sso/token`, payload)
        .then(async (res) => {
          const { data } = res.data;
          localStorage.setItem("consumer_id", data.data.user.consumer_id);
          localStorage.setItem("name", data.data.user.name);
          localStorage.setItem("api_access_key", data.data.user.api_access_key);
          localStorage.setItem("mode", data.data.user.mode);
          localStorage.setItem("appOrigin", params.get("appOrigin"));
          if (params.get("enterpriseId")) {
            localStorage.setItem("enterpriseId", params.get("enterpriseId"));
          }
          await getDetails();
          setIsLoading(false);
          // setTimeout(() => {
          //     navigate("/admin/dashboard");
          // }, 1000);
          resolve();
        })
        .catch((err) => {
          console.log(err);
          // toast.error(err.message)
          if (err.response.status == 401) {
            setTestMode(true);
          }
          setIsLoading(false);
          reject(err);
          // setTimeout(() => {
          //     navigate("/");
          // }, 3000);
        });
  });
  return (
    <>
      {isLoading && <Loader />}
      {!testMode ? (
        <div>
          <Navbar />
          <div className="login_page container_bb">
            <div className="login_page_left">
              <img src="/beanbag-assets/img/login_ilustration.png" alt="" />
            </div>
            <div className="login_page_right">
              <h1>SSO Login</h1>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
          <div className="first-job-sec container_bb" style={{ height: "500px" }}>
            <h4
                className="sub_headig"
                style={{ textAlign: "center", marginTop: "128px" }}
            >
              <img alt="" src="/beanbag-assets/img/Access-denied.svg" />
            </h4>
            <p className="mt-10 f-24 font-weight-800 light-text" style={{ textAlign: "center" }}>Access Denied</p>
            <p className="mt-30 mb-70 f-16 font-weight-600 light-text" style={{ textAlign: "center" }}>
              You don't have permission to access this page. Please contact support team to enable this option for your
              enterprise.
            </p>
          </div>
      )}
      <ToastContainer />
    </>
  );
}
