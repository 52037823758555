import React from 'react';
import { useNavigate } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import { useEffect, useState, useRef } from "react";
import moment from "moment";
import ApiCalls from "../api/index";
import Pagination from '@mui/material/Pagination';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { closeModelWindow } from '../helpers/helper.js';
import Box from "@mui/material/Box";
import ClearIcon from "@mui/icons-material/Clear";
import Grid from "@mui/material/Grid";
import PaginationItem from "@mui/material/PaginationItem";

import noJobsFound from "../assets/images/no-job-found.png";
import { ReactComponent as ArrowBackIcon } from "../../src/assets/images/arrow-left.svg";
import { ReactComponent as ArrowForwardIcon } from "../../src/assets/images/arrow-right.svg";
import { ReactComponent as SearchIcon } from "../../src/assets/images/search-icon.svg";
import Loader from "../Loader";
import { Typography, Stack, InputAdornment, TextField, Input, Popover } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import { formatDate } from '../helpers/helper';
import { makeStyles } from "@material-ui/core/styles";
const Constant = require("../helpers/constants");

export default function JobsListingPage() {
   const [jobs, setJobs] = useState([]);
   const [jobTitleSearch, setJobTitleSearch] = useState('');

   const [pageNumber, setPageNumber] = useState(1);
   const [pageCount, setPageCount] = useState(0);
   const [totalCount, setTotalCount] = useState(0);
   const [totalPage, setTotalPage] = useState(0);
   const [rowsperpage, setRowsPerPage] = React.useState(10);
   let appOrigin = localStorage.getItem("appOrigin");

   const [popup, setPopup] = useState({ show: false,  job_id: null });

   const [isLoading, setIsLoading] = useState(false);

   const [jobBoard, setJobBoard] = useState([]);
   const [anchor, setAnchorEl] = useState(null);
   const [open, setOpen] = useState(false);
   const id = open ? "simple-popover" : undefined;

   let navigate = useNavigate();

   const goToJobLogs = (job_id, job_name) => {
      navigate(`/admin/job-details/${job_id}`, { state: { job: job_name } });
   };

   useEffect(() => {
      document.title = `Job Listing | ${ appOrigin == 'H' ? Constant.APP_NAME_DIRECT_HEALTH : appOrigin == 'N' ? Constant.APP_NAME_NEURODIVERSIFY : Constant.APP_NAME_GENERAL }`;
      getJobs();
      window.scrollTo({ top: 0, behavior: "smooth" });
   }, []);

   const ref = useRef();

   useEffect(() => {
      ref?.current?.scrollIntoView();
   }, [ref?.current, pageNumber]);


   const handleClick = (e, job) => {
      setJobBoard(job.job_boards);
      setAnchorEl(e.currentTarget);
      setOpen(true);
    };

    const handleClosePopover = () => {
      setOpen(false);
    };

   const getJobs = () => {
      setIsLoading(true);
      ApiCalls.get(`/api/v1/jobs?search=${jobTitleSearch}&page=${pageNumber}`)
      .then((res) => {
         const { data } = res.data.data;
         setJobs(data);

         setPageNumber(res.data.data.page_number);
         setPageCount(res.data.data.page_count);
         setTotalCount(res.data.data.total_count);
         setTotalPage(res.data.data.total_pages);
         setIsLoading(false);
      }).catch(function (error) {
         setIsLoading(false);
         console.log(error);
      });
   };

   function getStatusText(status) {
      if (status === "C") {
        return "Completed";
      } else if (status === "R") {
        return "Dropped";
      } else if (status === "P") {
        return "Ongoing";
      } else if (status === "A") {
        return "Active";
      }
   }

   const handleDeleteJob = (job_id) => {
      setPopup({
         show: true,
         job_id: job_id
      });
   };

   const handleDeleteTrue = () => {
      if (popup.show && popup.job_id) {
         setIsLoading(true);
         ApiCalls.delete(`/api/v1/jobs/${popup.job_id}`)
         .then((res) => {
            setJobs(jobs.filter( (job) => job.job_id !== popup.job_id));
            closeModelWindow();
            getJobs();
            setPopup({
               show: false,
               job_id: null,
            });
            toast.success("Job deleted successfully.");
            setIsLoading(false);
         }).catch(function (error) {
            setIsLoading(false);
            console.log(error);
         });
      }
   };

   const handleDeleteFalse = () => {
      closeModelWindow();
      setPopup({
         show: false,
         job_id: null,
      });
   };

   const handlePageChange = (event, value) => {
      setIsLoading(true);
      window.scrollTo({ top: 0, behavior: "smooth" });
      ApiCalls.get(`/api/v1/jobs?search=${jobTitleSearch}&page=${value}`).then((res) => {
         const { data } = res.data.data;
         setJobs(data);

         setPageNumber(value);
         setPageCount(res.data.data.page_count);
         setTotalCount(res.data.data.total_count);
         setTotalPage(res.data.data.total_pages);
         setIsLoading(false);
      }).catch(function (error) {
         setIsLoading(false);
          console.log(error);
      });
   };

   const clearSearch = () => {
      setIsLoading(true);
      setJobTitleSearch('');
      ApiCalls.get(`/api/v1/jobs`).then((res) => {
         const { data } = res.data.data;
         setJobs(data);

         setPageNumber(res.data.data.page_number);
         setPageCount(res.data.data.page_count);
         setTotalCount(res.data.data.total_count);
         setTotalPage(res.data.data.total_pages);
         setIsLoading(false);
      }).catch(function (error) {
         setIsLoading(false);
          console.log(error);
      });
   };

   const handleEnterKey = (e) => {
      setJobTitleSearch(e.target.value);
      if (e.key === "Enter") {
         e.preventDefault();
         getJobs();
      }
      if (e.type === "input" && e.target.value === "") {
         e.preventDefault();
         clearSearch();
       }
   };
   let date = moment().format('YYYY-MM-DD');
   return (
      <>
        <div className="dash_content" ref={ref} style={{ height: '100vh' }} id="joblisting">
            {isLoading && <Loader />}
            <div className="table-wrapper">
               <div className="row job-head">
                  <div className="col-md-2 heading-text-color">
                     <h4>
                        <span className='heading-text-color'>Job Listing</span>
                     </h4>
                  </div>
                  <div className="col-md-10">
                     <div className="input_right">
                     <Input
                        disableUnderline
                        onKeyPress={handleEnterKey}
                        onInput={handleEnterKey}
                        onKeyDown={handleEnterKey}
                        value={jobTitleSearch}
                        type="text"
                        placeholder='Search jobs'
                        className="job_search inputcontrast "
                        endAdornment={
                        <InputAdornment position="end">
                              {( jobTitleSearch !== "" &&
                                 <ClearIcon
                                 onClick={() => {
                                  clearSearch();
                                }}
                                 tabIndex={-1}
                                 className='cursor-hover mr-20'
                                 aria-label="Clear icon"
                              />
                              )}
                              <Typography className="search-byId">
                              <Grid
                                 className="js-background border-radius-6 d-flex align-center cursor-hover p-13"
                                 onClick={() => {
                                  getJobs();
                                }}
                                 aria-label="Search icon"
                                 disableRipple="true"
                              >
                                 <SearchIcon
                                    width="20px"
                                    aria-label="search icon"
                                    className='svg-icon'
                                 />
                              </Grid>
                              </Typography>
                        </InputAdornment>
                        }
                        />
                     </div>
                  </div>
               </div>
               <div className="job_listing_table">
                  <div className="row">
                     <div className="col-md-12 p-inherit">
                        <div className="table_inner table-responsive">
                           <table className="table job-listing-table">
                              <thead>
                              {totalCount === 0
                                 ?
                                    ''
                                 :
                                    <tr className="tr_target">
                                       <th width="2%"></th>
                                       <th scope="col" className='job-title-heading'>Job Title</th>
                                       <th scope="col">Job Reference #</th>
                                       <th scope="col">Job Board</th>
                                       <th scope="col" style={{ paddingLeft: "20px" }}>Posted Date</th>
                                       {/* <th scope="col" className="center_heading">Status</th> */}
                                       <th scope="col" className="center_heading" style={{ textAlign: "left", paddingLeft: "32px" }}>Action</th>
                                    </tr>
                              }
                              </thead>
                              <tbody>
                                 {totalCount === 0
                                    ?
                                       <div className="dash_content center_heading">
                                          <Typography className="text-center">
                                             <img width="300px" height="300px" className="profile-video-thumbnail" src={noJobsFound} alt="img"/>
                                             <p className='no-data-found'>No jobs found</p>
                                          </Typography>
                                       </div>
                                    :
                                       jobs.map( (job, index) => {
                                          return (
                                             <>
                                                <tr className="tr_target m_tr_target list-hover-color" key={index}>
                                                   <td className='td_target job-boards-titles'>
                                                      {((pageNumber - 1) * 10) + index + 1}.
                                                   </td>
                                                   <td  className="td_target job-boards-titles">
                                                      {job.job_title}
                                                   </td>
                                                   <td  className="td_target job-boards-titles">
                                                      {job.job_reference_number ? job.job_reference_number : "-"}
                                                   </td>
                                                   <td className="td_target job-boards-titles">
                                                      {job.job_boards.length <= 0 ? <Box>-</Box> :
                                                         <Box>
                                                            {job.job_boards.map((boards, index) => {
                                                               return index < 3 ?
                                                                  (
                                                                     <span key={index} className="boards text-initial">
                                                                        {boards}{(index === job.job_boards.length - 1 || index === 2) ? " " : ", "}
                                                                     </span>
                                                                  ) : null;
                                                            })}
                                                            {
                                                               job.job_boards.length > 3 && (
                                                                  <Box style={{ display: 'inline-flex' }}>
                                                                        <a
                                                                        className='primary-color cursor-pointer'
                                                                        onClick={(e)=>handleClick(e, job)}
                                                                        >{`+${job.job_boards.length - 3} more`}</a>
                                                                  </Box>
                                                               )
                                                            }
                                                         </Box>
                                                      }
                                                   </td>
                                                   <td className="td_target job-boards-titles">{moment(job.request_timestamp).format("MMM DD, YYYY HH:mm")}</td>
                                                   {/* <td className="td_target center_heading">
                                                      <div className="form-group">
                                                         <select className={`${
                                                                        job.status === 'P'?'':job.status === 'R'?'dropped_option':'Yet to post'
                                                                     }`}>
                                                            <option>{ getStatusText(job.status)}</option>
                                                            <option>2</option>
                                                            <option>3</option>
                                                            <option>4</option>
                                                         </select>
                                                      </div>
                                                   </td> */}

                                                   <td className="center_heading td_target" style={{ textAlign: "left", paddingLeft: "32px" }}>
                                                      <a className='primary-color cursor-pointer' onClick={ () => goToJobLogs(job.log_id, job.job_title) }><span>View Job Logs</span></a>
                                                      {/* <i className='cursor-pointer'><img onClick={() => {handleDeleteJob(job.log_id);}} src="/beanbag-assets/img/trash_black.png" className="black_img" data-toggle="modal" data-target="#deleteJobModalConfirm" alt="" /></i> */}
                                                      {(formatDate(new Date(JSON.parse(JSON.stringify(job?.valid_till).replace("23:59:59", "00:00:00")))) < formatDate(date)) && (
                                                         <div className='m_expired_label' style={{ paddingLeft: "0px", paddingTop: "20px" }}>
                                                            <div className="light-text f-14 font-weight-500 expired_label">Expired</div>
                                                         </div>
                                                      )}
                                                   </td>
                                                </tr>
                                             </>
                                          );
                                       })
                                    }
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="row">
                     {totalCount === 0
                        ?
                        ''
                        :
                        <div className="col-md-6 p-inherit col-xs-12">
                           <p className="s-p-l-ten">Showing <span className="per-page-count-box">10</span> items per page</p>
                        </div>
                     }
                     {totalCount >= 10 &&
                     <div className="col-md-6 col-xs-12 r_mt-10">
                     <div className="pagination pagenation-design">
                        <>
                        <Grid
                        item
                        lg={6}
                        md={6}
                        xs={12}
                        className="justify-end d-flex pagenation-table navbarUser pt-8"
                        >
                        <Stack spacing={2} className="pagenation-joblist">
                           <Pagination
                              shape="rounded"
                              // color="primary"
                              rowsperpage={rowsperpage}
                              count={
                              Math.floor(totalCount / 10) +
                              (totalCount % 10 === 0 ? 0 : 1)
                              }
                              // page={page}
                              onChange={handlePageChange}
                              renderItem={(item) => (
                              <PaginationItem
                                 components={{
                                    last: (props) => (
                                    <button
                                       className="btn primary-button profileButton"
                                       {...props}
                                    >
                                       Last
                                    </button>
                                    ),

                                    next: (props) => (
                                    <Box
                                       className="d-flex align-center cursor-hover"
                                       {...props}
                                    >
                                       <Box className="gray7 f-14 align-center font-weight-500 ml-10 mr-20">
                                          Next
                                       </Box>
                                       <ArrowForwardIcon
                                          height="13px"
                                          width="13px"
                                       />{" "}
                                    </Box>
                                    ),

                                    first: (props) => (
                                    <Box
                                       className="d-flex align-center cursor-hover"
                                       {...props}
                                    >
                                       <ArrowBackIcon
                                          height="13px"
                                          width="13px"
                                       />{" "}
                                       <Box className="gray7 f-14 align-center font-weight-500 ml-10 mr-20">
                                          Previous
                                       </Box>
                                    </Box>
                                    ),

                                    previous: (props) => (
                                    <Box
                                       className="d-flex align-center cursor-hover"
                                       {...props}
                                    >
                                       <ArrowBackIcon
                                          height="13px"
                                          width="13px"
                                       />{" "}
                                       <Box className="gray7 f-14 font-weight-500 ml-10 mr-20">
                                          Previous
                                       </Box>
                                    </Box>
                                    ),
                                 }}
                                 {...item}
                              />
                              )}
                              showFirstButton={false}
                              showLastButton={false}
                           />
                        </Stack>
                        </Grid>
                        </>
                     </div>
                     </div>
                 }

               </div>
            </div>
        </div>
        <div className="modal fade post-job-model" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
               <div className="modal-content">
                  <div className="modal-header">
                     <h5 >Post the Job</h5>
                     <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                     </button>
                  </div>
                  <div className="modal-body">
                     <p >Job:&nbsp;<span><b>UX Designer</b></span></p>
                     <div className="row button_group">
                        <div className="col-md-12">
                        <button className="btn model_btn">Select all</button>
                        <button className="btn model_btn">Monster</button>
                        <button className="btn model_btn">LinkedIn</button>
                        <button className="btn model_btn">Career Builder</button>
                        <button className="btn model_btn">Indeed</button>
                        </div>
                     </div>
                  </div>
                  <div className="modal-footer text-center" style={{ borderTop: "none" }}>
                     <button type="button" className="btn model-post-btn btn-primary text-center">Post Job</button>
                  </div>
               </div>
            </div>
         </div>
         {/* <ToastContainer/> */}
         <div className="modal fade post-job-model post-job-model_2" id="deleteJobModalConfirm" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
               <div className="modal-dialog" role="document">
                  <div className="modal-content modal-content_ans modal_yes">
                     <div className="modal-header modal_head">
                           <h5
                              >Delete Job</h5>
                           {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                           <span aria-hidden="true">&times;</span>
                           </button> */}
                     </div>
                     <div className="modal-body mid_modal">
                              <p className="opening opening_4">Are you sure you want to delete the selected job?</p>
                              {popup.show && (
                                 <div className="answers">
                                       <span onClick={handleDeleteFalse}><a className="sub_answer cursor-hover">No</a></span>
                                       <span onClick={handleDeleteTrue}><a className="sub_answer_2 cursor-hover">Yes</a></span>
                                 </div>
                              )}
                     </div>

                  </div>
               </div>
         </div>
         <Popover
            id={id}
            open={open}
            anchorEl={anchor}
            onClose={handleClosePopover}
            anchorOrigin={{
               vertical: "-500",
               horizontal: "200",
             }}
             anchorPosition={{
               left: 500,
             }}
             transformOrigin={{
               vertical: "300",
               horizontal: "200",
             }}
         >
            <Grid item sx={{ p: 1 }}>
               <Box className="align-between">
                  <Grid item xs={12}>
                     <Typography
                        id=""
                        className="d-flex align-between p-10"
                     >
                        <Typography variant="h5" className="f-16 font-weight-600">
                           Job Board
                        </Typography>
                        <Typography
                           align="right"
                           className="cursor-hover gray7 icon-button"
                        >
                           <CloseIcon
                           onClick={handleClosePopover}
                           onKeyPress={handleClosePopover}
                           aria-label="Close icon"
                           />
                        </Typography>
                     </Typography>
                  </Grid>
               </Box>
               <Grid
               className="p-10"
               style={{ maxHeight: "250px", overflow: "auto", maxWidth: "300px" }}
               >
                  <Box>
                     {jobBoard.map((boards, index) => {
                        return (
                           <span key={index} className="job_boards">
                           {boards}
                           </span>
                        );
                     })}
                  </Box>
               </Grid>
            </Grid>
         </Popover>
      </>
  );
}
